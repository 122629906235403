import { ApolloProvider } from "@apollo/client";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
//Analytics
import { hotjar } from "react-hotjar";
// Apollo
import { useApollo } from "../apollo";
// Authentication
import { AuthProvider } from "../authentication";
import * as ga from "../core/helpers/gtag";
// Sentry
import sentryConfig from "../sentry";

sentryConfig();

function App({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  const isBrowser = typeof window !== "undefined";

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (isBrowser) {
      if (process.env.NODE_ENV === "production") hotjar.initialize(2284541, 6);
    }
  }, [isBrowser]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="/fonts/fonts.css" />
      </Head>
      <AuthProvider>
        <Component {...pageProps} />
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
