import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

export default function sentryConfig() {
  const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

  if (dsn) {
    const {
      serverRuntimeConfig: { rootDir },
    } = getConfig();
    const distDir = `${rootDir}/.next`;

    Sentry.init({
      enabled: process.env.NODE_ENV === "production",
      integrations: [
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename.replace(distDir, "app:///_next");
            return frame;
          },
        }),
      ],
      dsn,
    });
  }
}
